body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-large {
  margin: 30px auto 20px auto;
  width: 300px;
  height: 50px;
}

.mr-3 {
  margin-right: 50px;
}

.mb-3 {
  margin-bottom: 50px;
}

.simple-card__table {
  margin-top: 40px;
  margin-right: 100px;
  max-width: 300px;
  display: inline-block;
}

.simple-card__table-medium {
  margin-top: 40px;
  margin-right: 100px;
  max-width: 500px;
  display: inline-block;
}

.simple-card__table-big {
  margin-top: 40px;
  margin-right: 100px;
  max-width: 1000px;
  display: inline-block;
}

.th-opening {
  width: 100px;
}

.th-meaning {
  width: 350px;
}

.th-responses {
  width: 550px;
}

.system-name {
  font-size: 32px;
}

.text-teal {
  color: #009688;
}
